import request from '@/utils/request'

/**
 * 获取品牌数据
 */
export const getArticleList = () => {
  return request({
    url: 'contents/kjlm',
  })
}

/**
 * 获取证书数据
 */
export const getStuList = () => {
  return request({
    url: 'contents/product_service',
  })
}
/**
 * 修改排序
 */
export const articleSort = (data) => {
  return request({
    url: '/article/sort',
    method: 'POST',
    data
  })
}
/**
 * 删除文章
 */
export const deleteArticle = (articleId) => {
  return request({
    url: `/article/delete/${articleId}`
  })
}
/**
 * 获取文章详情
 */
export const articleDetail = (articleId) => {
  return request({
    url: `/article/${articleId}`
  })
}
/**
 * 新增品牌
 */
export const createArticle = (data) => {
  return request({
    url: '/contents/kjlm',
    method: 'POST',
    data
  })
}

/**
 * 新增品牌
 */
export const createStu= (data) => {
  return request({
    url: '/contents/product_service',
    method: 'POST',
    data
  })
}
/**
 * 编辑文章详情
 */
export const articleEdit = (data) => {
  return request({
    url: '/article/edit',
    method: 'POST',
    data
  })
}

/**
 * 上传图片
 */
export const uploadImg = (data) => {
  return request({
    url: '/upload/image',
    method: 'POST',
    data
  })
}

/**
 * 获取计划列表数据
 */
export const getplanList = (data) => {
  return request({
    url: '/film/plan_list',
    params: data
  })
}

/**
 * 新建计划
 */
export const upPlan = (data) => {
  return request({
    url: '/film/up_plan',
    method:'POST',
    data
  })
}

/**
 * 价格发布
 */
export const ticketPrice = (data) => {
  return request({
    url: '/film/ticket_price',
    method:'POST',
    data
  })
}


/**
 * 价格发布
 */
export const ticketPricechange = (data) => {
  return request({
    url: '/film/ticket_price_change',
    method:'POST',
    data
  })
}


/**
 * 订单列表
 */
export const getOrderlist = (data) => {
  return request({
    url: '/order/index',
    params:data
  })
}

/**
 * 计划票价回显
 */
export const priceInfo = (data) => {
  return request({
    url: `/film/price_info`,
    params:data
  })
}


/**
 * 剧目信息回显
 */
export const FilmInfo = (id) => {
  return request({
    url: `/film/info?id=${id}`,
  })
}



/**
 * 剧目信息回显
 */
export const excelExport = (id) => {
  return request({
    url: `/order/excel?fpid=${id}`,
  })
}
/**
* 文章查询接口
*/

export const articles = (data) => {
  return request({
    url: `/articles`,
    params:data
  })
}


/**
* 新建文章表单
*/

export const postArticles = (data) => {
  return request({
    url: `/articles`,
    method: 'POST',
    data
  })
}

/**
* 删除文章
*/

export const editarticles = (data) => {
  return request({
    url: `/articles/${data.id}`,
    method: 'PUT',
    data
  })
}

/**
* 编辑文章
*/

export const delArticles = (id) => {
  return request({
    url: `/articles/${id}`,
    method: 'DELETE',
  })
}